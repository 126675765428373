import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ 
  children, 
  allowedRoles = [], 
  sameId = false 
}) => {
  const { id } = useParams();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const userInfo = useSelector((state) => state.auth.userInfo);

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if ((!userInfo || !allowedRoles.includes(userInfo.ROE_id)) || 
      (sameId && userInfo.ROE_id === 1 && userInfo.USR_id !== parseInt(id))) {
    return <Navigate to="/accueil" replace />;
  }

  return children;
};

export default PrivateRoute;