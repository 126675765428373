import axios from './axiosConfig';

export const fetchCli = async () => {
    try {
        const response = await axios.get('/cli');
        return response;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const fetchCliName = async () => {
    try {
        const response = await axios.get('/cli/cli_Name');
        return response.data;
    } catch (error) {
        console.error('An error occurred while trying to fetch data:', error);
        return [];
    }
};

export const fetchCliTikFilter = async () => {
    try {
        const response = await axios.get('/cli/cli_TikFilter');
        const formattedClients = response.data
            .map(client => ({
                value: client.CLI_id,
                label: client.CLI_raisonSocial,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)); // Tri alphabétique
        return formattedClients;
    } catch (error) {
        console.error('An error occurred while trying to fetch data:', error);
        return [];
    }
};

export const fetchCliChaFilter = async () => {
    try {
        const response = await axios.get('/cli/cli_ChaFilter');
        const formattedClients = response.data
            .map(client => ({
                value: client.CLI_id,
                label: client.CLI_raisonSocial,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)); // Tri alphabétique
        return formattedClients;
    } catch (error) {
        console.error('An error occurred while trying to fetch data:', error);
        return [];
    }
};

export const fetchVilles = async () => {
    try {
        const response = await axios.get('/cli/villes'); // Remplacez par l'URL de votre API
        const formattedVilles = response.data
        formattedVilles.sort((a, b) => a.label.localeCompare(b.label));
        
        return formattedVilles;
    } catch (error) {
        console.error('Error fetching villes:', error);
        return [];
    }
};

export const fetchCliById = async (id) => {
    try {
        const response = await axios.get(`/cli/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const createClient = async (values) => {
    try {
        const response = await axios.post('/cli', values);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const updateClientById = async (id, values) => {
    try {
        const response = await axios.put(`/cli/${id}`, values);
        return response.data;
    } catch (error) {
        console.error('Error updating client:', error);
        throw error;
    }
};