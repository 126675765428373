import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { FiSearch } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { search } from '../../api/search'; // Fonction pour appeler ton API
import '../../styles/common/searchBar.css';

// Icônes pour chaque type de résultat
const optionTypeAbbreviation = {
  USR: 'USR',  // Utilisateur
  CLI: 'CLI',  // Client
  TIK: 'TIK'   // Ticket
};

// Fonction pour afficher une puce ou icône devant chaque option
const CustomOption = (props) => {
  const { data, innerRef, innerProps, isFocused } = props;

  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={`custom-option ${isFocused ? 'focused' : ''}`} // Ajout de la classe 'focused' lorsque l'option est focalisée
    >
      <span className={`option-badge badge-${data.type.toLowerCase()}`}>
        {optionTypeAbbreviation[data.type]} {/* Affiche l'abréviation basée sur le type */}
      </span>
      <div className="option-details">
        <div className="option-label">{data.label} - &nbsp;</div>
        {data.type === 'USR' && (
          <div className="option-subtext">{data.email}</div>
        )}
        {data.type === 'CLI' && (
          <div className="option-subtext">
            {data.raisonSocial}
            {data.contrat && (
              <span className="option-badge badge-contrat">{data.contrat}</span>
            )}
          </div>
        )}
        {data.type === 'TIK' && (
          <div className="option-subtext">
            {data.client}
            {data.contrat && (
              <span className="option-badge badge-contrat">{data.contrat}</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const GlobalSearchBar = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const navigate = useNavigate();
  // Fonction qui appelle l'API et retourne les résultats pour l'autocomplétion
  const loadOptions = async (inputValue) => {
    if (inputValue.length < 2) return [];
    setLoading(true);
    setError(null);
    try {
      // Appelle ton API de recherche avec la requête
      const data = await search(inputValue, userInfo.ROE_id);
      // Reformate les résultats pour React-Select
      return [
        ...data.users.map(user => ({
          value: user.USR_id,
          label: user.USR_initial,
          email: user.USR_mail,
          type: 'USR'
        })),
        // N'inclure les clients que si le rôle n'est pas 1
        ...(userInfo.roleId !== 1 ? data.clients.map(client => ({
          value: client.CLI_id,
          label: client.CLI_num,
          raisonSocial: client.CLI_raisonSocial,
          contrat: client.COTs && client.COTs.length > 0 ? client.COTs[0].COT_numContrat : null,
          type: 'CLI'
        })) : []),
        ...data.tickets.map(ticket => ({
          value: ticket.TIK_id,
          label: ticket.TIK_num,
          client: ticket.CLI.CLI_raisonSocial,
          contrat: ticket.CLI.COTs && ticket.CLI.COTs.length > 0 ? ticket.CLI.COTs[0].COT_numContrat : null,
          type: 'TIK'
        }))
      ];
    } catch (err) {
      setError('Erreur lors de la recherche');
      return [];
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour gérer la sélection d'une option
  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      const { value, type } = selectedOption;
      let url = '';

      switch (type) {
        case 'USR':
          url = `/user/${value}`;
          break;
        case 'CLI':
          url = `/client/${value}`;
          break;
        case 'TIK':
          url = `/tickets/${value}`;
          break;
        default:
          break;
      }
      // Rediriger vers l'URL appropriée
      setSelectedValue(null);
      navigate(url);
    }
  };

  return (
    <div className="d-flex search-form">
      <div className="search-wrapper">
        <FiSearch className="search-icon" />
        <AsyncSelect
          cacheOptions
          loadOptions={loadOptions}
          defaultOptions={[]}
          placeholder="Recherche"
          classNamePrefix="search-input"
          isLoading={loading}
          components={{ Option: CustomOption }}
          value={selectedValue}
          onChange={handleSelectChange}
        />
      </div>
    </div>
  );
};

export default GlobalSearchBar;