import axios from './axiosConfig';

export async function search(query, roleId) {
    try {
        const response = await axios.get(`/search/${query}/${roleId}`);
        return response.data;
      } catch (error) {
        console.error('Error during search:', error);
        throw error;
      }
}