import React, { useState, useCallback, memo } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { FiUser, FiUsers, FiLogOut, FiFileText, FiMenu } from 'react-icons/fi';
import { TbShovel } from "react-icons/tb";
import { GoGear } from "react-icons/go";
import { BsScrewdriver } from "react-icons/bs";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout, resetState } from '../../store/slices/authSlice';
import { persistor } from '../../store/store';
import Logo from '../../assets/logoSYSCAB.webp';
import GlobalSearchBar from './GlobalSearchBar';

import '../../styles/common/navbar.css';

const Navigation = () => {
  const [open, setOpen] = useState(false);
  const userInfo = useSelector((state) => state.auth.userInfo);  // Récupération des infos utilisateur depuis Redux
  const display = useSelector((state) => state.auth.display);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeMenuOnSmallScreens = useCallback(() => {
    setOpen(false);
  }, []);

  const handleLogout = useCallback(async () => {
    try {
      await dispatch(logout()).unwrap();
      dispatch(resetState());
      persistor.purge();
      navigate('/login');
    } catch (error) {
      console.error('Logout Error:', error);
    }
  }, [dispatch, navigate]);

  return (
    <Navbar className='styledNavbar' expand="lg" sticky="top" >
      <Container fluid>
        <div className='navLeft'>
          <div>
            <Navbar.Brand as={Link} to="/accueil">
              <img src={Logo} alt="Logo" width="50" height="50" />
            </Navbar.Brand>
          </div>
          <FiMenu className='responsiveHamburger' onClick={() => setOpen(!open)} />
        </div>
        <Navbar.Collapse id="basic-navbar-nav" in={open}>
          <Nav className="responsiveNavbar">
            {(userInfo && (userInfo.ROE_id === 3 || userInfo.ROE_id === 4)) &&
              <GlobalSearchBar />
            }
            <Link as={Link} to="/tickets" className="btn btn-outline-dark navLink" onClick={closeMenuOnSmallScreens}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <BsScrewdriver style={{ marginRight: '5px' }} /> Tickets
              </span>
            </Link>
            {(userInfo && (userInfo.ROE_id === 3 || userInfo.ROE_id === 4)) &&
              <>
                <Link as={Link} to="/chantiers" className="btn btn-outline-dark navLink" onClick={closeMenuOnSmallScreens}>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <TbShovel size={25} style={{ marginRight: '5px' }} /> Chantiers
                  </span>
                </Link>
                <Link as={Link} to="/users" className="btn btn-outline-dark navLink" onClick={closeMenuOnSmallScreens}>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <FiUsers style={{ marginRight: '5px' }} /> Utilisateurs
                  </span>
                </Link>
                <Link as={Link} to="/clients" className="btn btn-outline-dark navLink" onClick={closeMenuOnSmallScreens}>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <FiFileText style={{ marginRight: '5px' }} /> Clients
                  </span>
                </Link>
              </>
            }
            <Link as={Link} to={userInfo ? `/user/${userInfo.USR_id}` : '/login'} className="btn btn-outline-dark navLink" onClick={closeMenuOnSmallScreens}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <FiUser style={{ marginRight: '5px' }} /> Profil
              </span>
            </Link>
            {(userInfo && (userInfo.ROE_id === 4)) && (display && (display === "pc")) &&
              <Link as={Link} to={userInfo ? `/backOffice` : '/login'} className="btn btn-outline-dark navLink" onClick={closeMenuOnSmallScreens}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <GoGear style={{ marginRight: '5px' }} /> Backoffice
                </span>
              </Link>
            }
            <Link as={Link} to="#" className="btn btn-primary navLink" onClick={handleLogout}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <FiLogOut style={{ marginRight: '5px' }} /> Déconnexion
              </span>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default memo(Navigation);
